<template>
	<div class="shiftCard" style="background: white; padding: 1.6rem; width:100%;">
    
    <Loader v-if="performingRequest" />

    <span class="flex justify-space-between">

      <span>
        <h2 v-if="shift.event">{{shift.event}}</h2>
    		<h3 v-if="shift && shift.position" class="mb-0"><span v-if="shift.name">{{shift.name}} - </span>{{shift.position.title}}</h3>
      </span>
    </span>
		<div v-if="shift && shift.position" class="mb-2">${{shift.position.rate}}/hour</div>
    <div class="strong caption mb-2" v-if="shift && shift.address">
      <div>{{shift.address.street_number}} {{shift.address.street}}</div>
      <div>{{shift.address.city}}, {{shift.address.state}} {{shift.address.zip}}</div>
      </div>

    <div class="strong mb-1" v-if="shift && shift.details && isConfirmed">Shift Details</div>
    <div class="caption mb-1" v-if="shift && shift.details && isConfirmed" v-html="shift.details"></div>

		<hr class="mt-3">
    <div v-if="isAssigned || isConfirmed">
		  <h5 v-if="shift && shift.event && !isPermanent">{{shift.event}}</h5>
    </div>

		
    <div v-if="isPermanent">
      <div class="strong mb-1">Shift Schedule</div>
      <div class="caption mb-1" v-if="shift && shift.sundayStart && shift.sundayEnd">
        Sunday: {{formatAMPM(shift.sundayStart)}} - {{formatAMPM(shift.sundayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.mondayStart && shift.mondayEnd">
        Monday: {{formatAMPM(shift.mondayStart)}} - {{formatAMPM(shift.mondayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.tuesdayStart && shift.tuesdayEnd">
        Tuesday: {{formatAMPM(shift.tuesdayStart)}} - {{formatAMPM(shift.tuesdayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.wednesdayStart && shift.wednesdayEnd">
        Wednesday: {{formatAMPM(shift.wednesdayStart)}} - {{formatAMPM(shift.wednesdayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.thursdayStart && shift.thursdayEnd">
        Thursday: {{formatAMPM(shift.thursdayStart)}} - {{formatAMPM(shift.thursdayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.fridayStart && shift.fridayEnd">
        Friday: {{formatAMPM(shift.fridayStart)}} - {{formatAMPM(shift.fridayEnd) | moment("h:mm:ss a") }}
      </div>
      <div class="caption mb-1" v-if="shift && shift.saturdayStart && shift.saturdayEnd">
        Saturday: {{formatAMPM(shift.saturdayStart)}} - {{formatAMPM(shift.saturdayEnd) | moment("h:mm:ss a") }}
      </div>

    </div>

    <div class="mb-1 strong" v-if="((shift && shift.day) && !isPermanent)">{{shift.day | moment("dddd, MMMM Do") }}</div>

    <!-- <div class="caption mb-1 strong" v-if="shift && shift.day">{{shift.day | moment("dddd, MMMM Do") }}</div> -->

    <div class="caption mb-1" v-if="shift && shift.startTime && !isPermanent">{{formatAMPM(shift.startTime) | moment("h:mm:ss a") }} - {{formatAMPM(shift.endTime) | moment("h:mm:ss a") }}</div>

		<hr>

    <div>
      <h5 v-if="shift && shift.eventInfo && shift.eventInfo.venue && shift.eventInfo.venue.address">{{shift.eventInfo.venue.title}}</h5>
      <div class="strong caption mb-2" v-if="shift.eventInfo.venue.address">{{shift.eventInfo.venue.address.street_number}} {{shift.eventInfo.venue.address.street}} <span v-if="shift.eventInfo.venue.address.unit">#{{shift.eventInfo.venue.address.unit}}</span><br />{{shift.eventInfo.venue.address.city}}, {{shift.eventInfo.venue.address.state}} {{shift.eventInfo.venue.address.zip}}</div>
    </div>


    <span v-if="shift && shift.position.description">
      <hr class="mt-4" >
      <h5>{{shift.position.title}}:</h5>
      <div class="mb-3">{{shift.position.description}}</div>
    </span>

    <span v-if="shift && shift.eventInfo && shift.eventInfo.description">
      <hr class="mt-4" >
      <h5>Description:</h5>
      <div class="mb-3" v-html="shift.eventInfo.description"></div>
    </span>
    <span v-if="shift && shift.eventInfo.venue && shift.eventInfo.venue.creds">
      <hr class="mt-4" >
      <h5>Credentials:</h5>
      <div class="mb-3" v-html="shift.eventInfo.venue.creds"></div>
    </span>
    <span v-if="shift && shift.eventInfo.venue && shift.eventInfo.venue.attire">
      <hr class="mt-4" >
      <h5>Attire:</h5>
      <div class="mb-3" v-html="shift.eventInfo.venue.attire"></div>
    </span>
    <span v-if="shift && shift.eventInfo.venue && shift.eventInfo.venue.checkin">
      <hr class="mt-4" >
      <h5>Checking In:</h5>
      <div class="mb-3" v-html="shift.eventInfo.venue.checkin"></div>
    </span>
    <span v-if="shift && shift.eventInfo.venue && shift.eventInfo.venue.parking">
      <hr class="mt-4" >
      <h5>Parking:</h5>
      <div class="mb-3" v-html="shift.eventInfo.venue.parking"></div>
    </span>
    <span v-if="shift && shift.eventInfo.venue && shift.eventInfo.venue.camping">
      <hr class="mt-4" >
      <h5>Camping:</h5>
      <div class="mb-3" v-html="shift.eventInfo.venue.camping"></div>
    </span>
    <span v-if="shift && shift.eventInfo.venue && shift.eventInfo.venue.covid">
      <hr class="mt-4" >
      <h5>COVID:</h5>
      <div class="mb-3" v-html="shift.eventInfo.venue.covid"></div>
    </span>
    <span v-if="shift && shift.eventInfo.venue && shift.eventInfo.venue.notes">
      <hr class="mt-4" >
      <h5>Notes:</h5>
      <div class="mb-3" v-html="shift.eventInfo.venue.notes"></div>
    </span>
    

    <h5>Staffing Manager<span v-if="shift.eventInfo.mgrs && shift.eventInfo.mgrs.length >= 2">s</span>:</h5>
    <div v-for="(item, index) in shift.eventInfo.mgrs" :key="index" class="mb-3">
      <h6 class="mb-1">{{item.name}}</h6>
      <div class="mb-1"  v-if="item.phone">
        <a :href="`tel:${item.phone}`">{{item.phone}}</a></div>
      <div class="mb-1">
        <a :href="`mailto:${item.email}`">{{item.email}}</a>
      </div>
    </div>


    <hr v-if="shift &&  shift.position && shift.position.certs && shift.position.certs.length >= 1">

    <div class="mb-0" v-if="shift &&  shift.position && shift.position.certs && shift.position.certs.length >= 1">
      <h5 class="mb-2">Required Certifications:</h5>
      <button class="btn__small chip mr-3 mb-3" v-for="(item, index) in shift.position.certs">{{item.title}}</button>
    </div>

   <!--  <div class="mb-0" v-if="shift && shift.certs && shift.certs.length >= 1">
      <h5 class="mb-2">Required Certifications:</h5>
      <button class="btn__small chip mr-3 mb-3" v-for="(item, index) in shift.certs">{{item.title}}</button>
    </div>
 -->

    <hr v-if="isPermanent">
    <div class="caption mb-3" v-if="isPermanent">*This is a permanent or semi-permanent position.</div>

    <hr v-if="shift && shift.eventInfo && shift.eventInfo.covidVax">
    <div class="caption mb-3 danger" v-if="shift && shift.eventInfo && shift.eventInfo.covidVax">*This job requires proof of COVID vaccination.</div>

    <span v-if="(isConfirmed == true) && ((shift.eventInfo.files && shift.eventInfo.files.length >= 1) || (shift.files && shift.files.length >= 1))">

      <hr class="mt-1">

      <h5 class="pb-1">Attachments</h5>
        <div v-for="(item, index) in shift.eventInfo.files" :key="index + `a`">
          <div class="flex justify-space-between align-center pt-2 pb-2" style="border-bottom:1px solid rgba(0,0,0,0.1);">
            <span>
            <div class="strong">{{item.title}}</div>
            <span class="caption">{{item.description}}</span>
            </span>
            <a :href="item.url" target="_blank">
              <button class="btn btn__outlined btn__small">
                View Attachment<i class="fas fa-external-link ml-2"></i>
              </button>
            </a>
          </div>
        </div>
        <div v-for="(item, index) in shift.files" :key="index + `a`">
          <div class="flex justify-space-between align-center pt-2 pb-2" style="border-bottom:1px solid rgba(0,0,0,0.1);">
            <span>
            <div class="strong">{{item.title}}</div>
            <span class="caption">{{item.description}}</span>
            </span>
            <a :href="item.url" target="_blank">
              <button class="btn btn__outlined btn__small">
                View Attachment<i class="fas fa-external-link ml-2"></i>
              </button>
            </a>
          </div>
        </div>
    </span>

	</div>
</template>

<script>
import { mapState } from 'vuex'
import AvailabilityNote from '@/components/AvailabilityNote.vue'
import Loader from '@/components/Loader.vue'
// import AvailabilityNotice from '@/components/AvailabilityNotice.vue'
const fb = require('../../firebaseConfig.js')

export default {
	props: ['shift', 'showLink', 'assignment'],
	data: () => ({
    performingRequest: false,
    activeItem: null,
    activeApp: null,
    // requestedJob:{},
    activeShiftAssignments: [],
  }),
  async mounted() {
    this.getActiveShiftAssignments()
  },
	computed: {
    ...mapState(['currentUser', 'userProfile', 'userAvailableData']),
    jumpBlacklist() {
      if (this.userProfile && this.userProfile.blacklist) {
        return this.userProfile.blacklist.filter(item => {
          return item.id == 'v0VY62f0WrdUxM6lodxp'
        })
      }
    },
    isJumpBlacklist() {
      if (this.jumpBlacklist && this.jumpBlacklist.length >= 1) {
        return true
      } else {
        return false
      }
    },
    // matchingGroups() {
    //   if (this.userProfile && this.userProfile.groups && this.shift && this.shift.groupIds) {
    //   return this.shift.groupIds.filter(element => {
    //       return this.userProfile.groups.includes(element)
    //     });
    //   }
    // },
    // appliedGroups() {
    //   if (this.userProfile && this.userProfile.groups && this.shift && this.shift.groupIds) {
    //     return this.shift.groupIds.filter(element => {
    //       return this.userProfile.groupApplications.includes(element)
    //     });
    //   }
    // },
    // waitlistedGroups() {
    //   if (this.userProfile && this.userProfile.groups && this.shift && this.shift.groupIds) {
    //     return this.shift.groupIds.filter(element => {
    //       return this.userProfile.groupWaitlist.includes(element)
    //     });
    //   }
    // },
    isInGroup() {
      return false
      // if (this.matchingGroups && this.matchingGroups.length >= 1) {
      //   return true
      // } else if (!this.shift.groups || this.shift.groups.length == 0) {
      //   return true
      // } else {
      //   return false
      // }
    },
    // isAppliedGroup() {
    //   if (this.appliedGroups && this.appliedGroups.length >= 1) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    // isWaitlistedGroup() {
    //   if (this.waitlistedGroups && this.waitlistedGroups.length >= 1) {
    //     return true
    //   } else {
    //     return false
    //   }
    // },
    userAvailableDataDay() {
      return this.userAvailableData.filter(data => {
        return (data.day == this.shift.day)
      })
    },
    myAssignment() {
      if ((this.activeShiftAssignments && this.activeShiftAssignments.length >= 1) && this.currentUser && this.currentUser.uid) {
        return this.activeShiftAssignments.filter(assignment => {
          return ((assignment.userId == this.currentUser.uid) && (assignment.shiftId == this.shift.id))
        })
      } else {
        return null
      }

    },
    // event() {
    //   return this.eventInfo
    // },
    isCancelled() {
      return this.shift.eventStatus == 'cancelled'
    },
    isHold() {
      return this.shift.eventStatus == 'On-Hold'
    },
    isClosed() {
      return this.shift.eventStatus == 'Closed'
    },
    isOpen() {
      return (!this.shift.eventStatus || this.shift.eventStatus == 'Open')
    },
    isPermanent() {
      return this.shift && this.shift.type && (this.shift.type == 'Permanent' || this.shift.type == 'Job')
    },

    // !userAvailableDataDay || 
        //   userAvailableDataDay.length == 0 || 
        //   (userAvailableDataDay[0] && userAvailableDataDay[0].requestedShift && userAvailableDataDay[0].requestedShift.id != shift.id) && 
        //   (!userAvailableDataDay[0].dayStatus || userAvailableDataDay[0].dayStatus != 'not requested') &&
        //   (!myAssignment || (myAssignment && 
        //   myAssignment[0]))

    isAvailable() {
      if (
        !this.isPending && 
        //user is available to work this day
        (!this.userAvailableDataDay || !this.userAvailableDataDay[0]) || 
        //they have not already applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id != this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift != this.shift.id)) ||
        //they haven't been not requested
        (this.userAvailableDataDay[0].dayStatus || this.userAvailableDataDay[0].dayStatus != 'not requested')
        //they haven't been confirmed to work
        && 
        (!this.myAssignment) 
        // &&
        // they haven't been assigned another shift
        // (this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status != "assigned")
        ) {
        return true
      } else {
        return false
      }
    },
    isPending() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they haven't been requested
        (this.userAvailableDataDay[0].status && (this.userAvailableDataDay[0].status == 'available' || this.userAvailableDataDay[0].status == 'placed')) && 
        //they haven't been hired
        !this.userAvailableDataDay[0].dayStatus
        //they haven't been confirmed to work
        && 
        (!this.myAssignment || !this.myAssignment[0])
      ) { 
        return true
      } else {
        return false
      }
    },
    isHired() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they haven't been requested
        (this.userAvailableDataDay[0].status && (this.userAvailableDataDay[0].status == 'available' || this.userAvailableDataDay[0].status == 'placed')) && 
        //they have been hired
        (this.userAvailableDataDay && this.userAvailableDataDay[0] && this.userAvailableDataDay[0].dayStatus && this.userAvailableDataDay[0].dayStatus == 'hired')

      ) {
        return true
      } else {
        return false
      }
    },
    

    isNotHired() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they have been not hired
        ((this.userAvailableDataDay && this.userAvailableDataDay[0] && (this.userAvailableDataDay[0].dayStatus == 'not requested')))
      ) {
        return true
      } else {
        return false
      }
    },
    isAssigned() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they have been requested
        (this.userAvailableDataDay[0] && this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status == 'assigned') && 
        //they have been been assigned this shift
        (this.myAssignment && this.myAssignment[0] && (this.myAssignment[0].shiftId == this.shift.id)) &&
        //they have not accepeted yet
        (!this.myAssignment || !this.myAssignment[0] || !this.myAssignment[0].confirmed || 
          this.myAssignment[0].confirmed == false)
      ) {
        return true
      } else {
        return false
      }
    },
    isConfirmed() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        ((this.userAvailableDataDay[0].requestedShift && this.userAvailableDataDay[0].requestedShift.id == this.shift.id) || (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift == this.shift.id)) && 
        //they have been requested
        (this.userAvailableDataDay[0] && this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status == 'assigned') && 
        //they have been been assigned this shift
        (this.myAssignment && this.myAssignment[0] && (this.myAssignment[0].shiftId == this.shift.id)) &&
        //they have accepeted
        (this.myAssignment[0].confirmed)
      ) {
        return true
      } else {
        return false
      }
    },

    isConfirmedDifferentShift() {
      if (
        //user is available to work this day
        (this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        //they have applied to work this shift
        (this.userAvailableDataDay[0].shift && this.userAvailableDataDay[0].shift != this.shift.id) && 
        //they have been requested
        (this.userAvailableDataDay[0] && this.userAvailableDataDay[0].status && this.userAvailableDataDay[0].status == 'assigned') &&
        (!this.myAssignment || !this.myAssignment[0])
      ) {
        return true
      } else {
        return false
      }
    },
    // userAppCheck() {
    // 	return this.userAvailableDays.includes(this.event.days[0])
    // }
  },
  components: {
		AvailabilityNote,
		// AvailabilityNotice,
    Loader,
  },
	methods: {
    signUp() {
      this.$router.push('auth/signup')
    },
    async getActiveShiftAssignments() {
      // console.log(this.shift.id)
      if (this.shift && this.shift.id) {
        await fb.assignmentsCollection.where("shiftId", "==", this.shift.id).onSnapshot(querySnapshot => {
          let assignmentsArray = []
          querySnapshot.forEach(doc => {
            // console.log(doc.data())
            // store.dispatch("getEventFromId", doc.data().eventId)
            let shift = doc.data()
            assignmentsArray.push(shift)
          })
          return this.activeShiftAssignments = assignmentsArray
        })
      }
    },
		formatAMPM(date) {

      if (typeof date === "string") {
        // console.log(date)
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        if (date === "00:00") {
          return `12:00 AM`;
        } else {
          return `${hours}:${minutes} ${ampm}`;
        }

      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;
        return strTime;

      }
      return date;
      
    },

    onClickChild () {
      this.activeItem = null
    },
    selectItem(iten) {
      this.activeItem = iten
    },
    closeItem(item) {
      this.activeItem = null
    },
    appInfo(day) {
      return this.userAvailableData.filter(data => {
        return (data.userId == this.currentUser.uid) && (data.day == day) && ((data.requestedShift.id || data.shift) == this.shift.id)
      })
      return this.arr = day
    },  
    isApplyToGroup(shift) {
      this.performingRequest = true
      console.log(shift)
      this.$store.dispatch("applyToGroup", {
        group: {
          groupId: shift.groupIds[0],
        },
        user: this.userProfile
      });
      setTimeout(() => {
          this.performingRequest = false  
      }, 1500)
    },
    applyToWork() {
      this.performingRequest = true
      console.log('new job application')
      let event = {
        status: this.shift.eventStatus,
        title: this.shift.eventTitle,
        slug: this.shift.eventSlug,
      }
      this.$store.dispatch('addJobAvailablity', {
        user: this.userProfile,
        event: event,
        shift: this.shift
      })
      setTimeout(() => {
        this.performingRequest = false
      }, 3000)
    },
    isAvailableToWork(day) {
      // console.log(this.eventInfo)
      let user = this.userProfile
      let event = {
        status: this.shift.eventStatus,
        title: this.shift.eventTitle,
        slug: this.shift.eventSlug,
      }
      let shift = this.shift

      if ((this.userAvailableDataDay && this.userAvailableDataDay[0]) && 
        (this.userAvailableDataDay[0].shift != this.shift.id)) {
          console.log('switching')
          this.performingRequest = true
          this.$store.dispatch('switchAvailablity', {
            user: this.userProfile,
            day: day,
            // event: this.eventInfo,
            shift: this.shift,
            id: this.userAvailableData[0].id
          })
          setTimeout(() => {
            this.performingRequest = false
          }, 2000)
      }
      else {

        this.performingRequest = true
        console.log('new application')
        this.$store.dispatch('addAvailablity', {
          user: this.userProfile,
          day: day,
          event: event,
          shift: this.shift
        })
        setTimeout(() => {
          this.performingRequest = false
        }, 3000)
      }
    },
    notAvailable(day) {
      this.performingRequest = true
      let drop =  {
        userId: this.currentUser.uid,
        day: day
      }
      this.$store.dispatch('dropUser', drop)
      setTimeout(() => {
        this.performingRequest = false
      }, 2000)
    },
    confirm(item) {
      console.log(item)
      this.$store.dispatch('confirmFromId', item.id)
    }
	},
  beforeDestroy () {
    this.$store.dispatch('clearActiveShiftAssignments')
    // this.$store.dispatch("clearEventState")
  }
}
</script>