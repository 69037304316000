<template>
	<div class="modal-mask">
		<div class="modal-wrapper">
      <div class="modal-container">
      	
        <div class="modal-body pa-4" v-click-outside="onClickOutside">
        	<div v-if="userAvailableData">
        		<div class="flex justify-space-between align-center mb-2">
        			<h3>Anything we should know?</h3>
        			<button @click="$emit('close')">
			         <i class="fas fa-times mr-1"></i>
			        </button>
        		</div>
						
						<textarea name="know" id="know" cols="30" rows="5" placeholder="Who referred you, who you are traveling with, if you would be late, etc." v-model.lazy="userAvailableData.note"></textarea>
					</div>
					<button class="btn btn__outlined mt-3" @click="onUpdateNote">
						Save Note
						<transition name="fade">
				      <span class="ml-2" v-if="performingRequest">
				      <i class="fa fa-spinner fa-spin"></i>
				      </span>
				    </transition>
					</button>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
const fb = require('../firebaseConfig.js')

export default {
  props: ['userAvailableData', 'day'],
  data: () => ({
  	performingRequest: false,
  }),
  // computed: {
  // 	dayNote () {
  // 		return this.userAvailableData
  // 	},
  // 	firstData () {
  // 		return this.dayNote[0] || null
  // 	}
  // },
  methods: {
  	onClickOutside (event) {
  		this.$emit('close')
     },
  	onUpdateNote: function () {
  		this.performingRequest = true
  		let userAvailableData = this.userAvailableData
  		fb.userDaysCollection.doc(this.userAvailableData.id).update(userAvailableData)
			setTimeout(() => {
        this.performingRequest = false
        this.$emit('close')
      }, 1000)
    },
  },
}
</script>