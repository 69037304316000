<template>
  <div class="dashboard__container--body">
    <Loader v-if="!myShifts || myShifts.length == 0" />
    <div class="dashboard__container--body--col flex" v-for="(shift, index) in myShifts" :key="shift.id">
      <AssignmentCard :shift="shift" :assignment="true" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import AssignmentCard from '@/components/Shifts/AssignmentCard.vue'

export default {
  name: 'myShifts',
  computed: {
    ...mapState(['userProfile', 'currentUser', 'myShifts']),
  },
  // created() {
  //   this.$store.dispatch("getMyShifts")
  // },
  created () {
    // this.$store.dispatch("getAvailableShifts")
    this.$store.dispatch("getMyShifts")
  },
  components: {
    Loader,
    AssignmentCard
  },
}
</script>